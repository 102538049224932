import React from 'react'

import NavBar from '../components/navbar'

const Header = () => (
  
  <div
    style={{
      background: 'black',
      marginBottom: '0rem',
      display: 'flex',
      position: '-webkit-sticky',
      // eslint-disable-next-line
      position: 'sticky',
      top: 0,
      zIndex: 10,
    }}
  >
    <div
      style={{
        marginLeft: 10,
        padding: '0rem',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <div className="nav-bar">
        <NavBar />
      </div>
    </div>
  </div>
)

export default Header
