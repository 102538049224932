import React, { Component } from 'react'
import BandImage from '../components/bannerband'
import MainLogoImage from '../components/mainlogo'

class Player extends Component {
  render() {
    return (
      
      <div>
        <div className="main-logo-image"><MainLogoImage/></div>
  <BandImage/>
  
      </div>
    )
  }
}
export default Player
