import React, { Component } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import {
  FaBandcamp,
  FaFacebookF,
  FaTwitter,
  FaTwitch,
  FaYoutube,
  FaInstagram,
} from 'react-icons/fa'
import Image from '../components/image'
import { Link } from 'gatsby'

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }
  render() {
    return (
      <div>
        <Navbar color="black" dark expand="md">
          <NavbarBrand tag={Link} to={'/'}>
            <Image />
          </NavbarBrand>
          <div className="header-social">
              <a target="_blank" rel="noopener noreferrer" href="https://fb.com/thestagequebec">
                <FaFacebookF className="social-icon" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://twitch.tv/fpbrault">
                <FaTwitch className="social-icon" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/thestagequebec">
                <FaTwitter className="social-icon" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://thestagequebec.bandcamp.com">
                <FaBandcamp className="social-icon" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/myselfdestroy">
                <FaYoutube className="social-icon" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thestagequebec/">
                <FaInstagram className="social-icon" />
              </a>
            </div>
          <NavbarToggler color="secondary" onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            
            <Nav className="ml-auto" navbar>
{/*               <NavItem>
                <Link to="/live">
                  <h3>LIVESTREAM</h3>
                </Link>
              </NavItem> */}
              {/*               <NavItem>
                <Link to="/">
                  <h3>NEWS</h3>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/">
                  <h3>SHOWS</h3>
                </Link>
              </NavItem> */}
              <NavItem>
                <NavLink target="_blank" rel="noopener noreferrer" href="https://thestagequebec.bandcamp.com">
                  <h3>MUSIC</h3>
                </NavLink>
              </NavItem>
              <NavItem>
                <Link to="/photos">
                  <h3>PHOTOS</h3>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/videos">
                  <h3>VIDEOS</h3>
                </Link>
              </NavItem>
              {/*               <NavItem>
                <Link to="/">
                  <h3>STORE</h3>
                </Link>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}
export default NavBar
